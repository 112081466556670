<template>
    <div class="text-center pb-4">
    <div class="fs-4">عزيزي المتبرع</div>
    <div class="fs-4">للتبرع بمبلغ {{donation_amount}} ر.س ادخل معلومات البطاقة</div>
  </div>
</template>
<script> 
export default {
  name: "DonationAmount",  
  props: {
    donation_amount: {
      type: Number,
      required: true
    }
  }
};
</script>