import { ref, onUnmounted } from 'vue';
import { getLocalValue, removeLocalValue } from "@/hooks/useLocalStorage";

export function useScrollToSelectedCase(delay: number = 1000) {
  const timeoutId = ref<number | null>(null);

  const handleScroll = (): void => {
    const currentSelectedCase = getLocalValue('selected_case_id');
    
    if (currentSelectedCase) {
      const caseElement = document.getElementById(currentSelectedCase);
      if (caseElement) {
        caseElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
      removeLocalValue('selected_case_id');
    }
  };

  const startScroll = (): void => {
    timeoutId.value = window.setTimeout(() => {
      handleScroll();
    }, delay);
  };

  onUnmounted((): void => {
    if (timeoutId.value !== null) {
      clearTimeout(timeoutId.value);
    }
  });

  return {
    handleScroll,
    startScroll,
  };
}