<template>
  <DonationAmount :donation_amount="this.donation_amount"/>
  <div class="bg-white p-4 form-cont m-auto rounded-3 mb-5 dir-ltr">
    <form :action="redirectURI" class="paymentWidgets"
          :data-brands="dataBrands"></form>
  </div>

  <DonationSuccessDialog :showModal="showDonationSuccessModal"
                         :data="successDonationData"
                         :donationType="donationType"
                         heading="تم تفعيل خدمة التبرع الدوري بنجاح"
                         message="شكراً لمساهمتك في شفاء , و أجزل الله لك المثوبة والعطاء"
                         buttonLabel="صفحة التبرع الدوري"
                         :periodicDonation="true"
                         :buttonLink="true"
                         v-if="showDonationSuccessModal"
                         @close="showDonationSuccessModal = false"></DonationSuccessDialog>
  
  <DonationInProgressDialog :showModal="showInProgressModal"
                         :data="progessDonationData"
                         :periodicDonation="true"
                         v-if="showInProgressModal"
                         @close="showInProgressModal = false"/>  

  <DonationFailureDialog :showModal="showDonationFailureModal"
                         :message="paymentFailureMessage"
                         v-if="showDonationFailureModal"
                         @close="showDonationFailureModal = false"></DonationFailureDialog>
</template>

<script>
import hyperpayMixin from "@/mixins/hyperpayMixin";
import DonationService from "@/services/donation.service";
import { DONATION_TYPE } from "@/services/enum.service";
import DonationSuccessDialog from "@/components/donate/DonationSuccessDialog";
import DonationFailureDialog from "@/components/donate/DonationFailureDialog";
import DonationAmount from "@/components/donate/DonationAmount";
import {useRoute} from "vue-router";
import { getLocalValue, setLocalValue, removeLocalValue } from "@/hooks/useLocalStorage";
import { defineAsyncComponent} from "vue";
const DonationInProgressDialog = defineAsyncComponent(() =>
  import('@/components/donate/DonationInProgressDialog.vue')
)
export default {
  mixins: [hyperpayMixin],
  name: "Checkout",
  components: {DonationFailureDialog, DonationSuccessDialog, DonationInProgressDialog, DonationAmount},
  data() {
    return {
      cardType: "credit_card",
      redirectURI: "",
      showDonationSuccessModal: false,
      successDonationData: {},
      donationType: DONATION_TYPE.CASE,
      paymentFailureMessage: "",
      showDonationFailureModal: false,
      resourcePath: "",
      paymentTransactionId: 0,
      showInProgressModal:false,
      progessDonationData: {},
      route: useRoute(),
      donation_amount:null,
    }
  },
  mounted() {
    this.setDonationAmount();
    this.cardType = this.$route.query.card_type;
    if (this.$route.query.reference_id) {
      this.isAmbassador = true;
      this.referenceId = this.$route.query.reference_id;
    }

    this.redirectURI = process.env.VUE_APP_FE_URL + `/periodic-donation-checkout`;

    if (this.$route.query.id && this.$route.query.resourcePath) {
      this.resourcePath = this.$route.query.resourcePath;
      this.paymentTransactionId = this.$route.query.id;

      this.checkPaymentStatus();
    }
  },
  methods: {
    setDonationAmount() {
      if(this.route.query.donation_amount) {
        this.donation_amount = this.route.query.donation_amount;
      }
  },
    checkPaymentStatus() {
      const isPaymentExist = getLocalValue(this.paymentTransactionId.toString())
      if(isPaymentExist) {
        if(getLocalValue("paymentStatus")===202){
          this.setDonationProgressData(isPaymentExist)
          return
        }
        this.setDonationSuccessData(isPaymentExist)
        return
      }

      DonationService.getPaymentStatus(this.paymentTransactionId, this.resourcePath)
        .then(({ data: { response , status, errors} }) => {
          setLocalValue("paymentStatus",status);
          if (status===202){
            const wallet_id= getLocalValue("wallet");
              DonationService.getPaymentInfo(this.paymentTransactionId, wallet_id)
                .then(( data) => {
                      this.setDonationProgressData(data.data.result);
                      setLocalValue("paymentId", this.paymentTransactionId);
                      setLocalValue(this.paymentTransactionId, this.progessDonationData);
                })
                .finally(() => {
                   removeLocalValue("donationModel");
                   removeLocalValue("wallet");
           });
           return;
          }
          const donationModel = getLocalValue("donationModel");
          const postBody = {
            to_wallet: getLocalValue("wallet"),
            amount: donationModel.donation_amount,
            donor_mobile_number: donationModel.doner_mobile,
            checkout_id: this.paymentTransactionId,
            on_behalf_donor_name: donationModel.on_behalf_donor_name,
            gifted_to: donationModel.gifted_to === "أخرى" ? donationModel.gifted_to_text : donationModel.gifted_to,
            donor_relation: donationModel.donor_relation === "أخرى" ? donationModel.donor_relation_text : donationModel.donor_relation,
            gifted_mobile_number: donationModel.gifted_mobile_number,
            on_behalf_donation: donationModel.on_behalf_donation,
            link_of_the_donated_on_object: window.location.href
          };

          DonationService.donate(postBody)
            .then(({ data: { result: { donation_success_data } } }) => {
              this.setDonationSuccessData(donation_success_data);
              setLocalValue("paymentId", this.paymentTransactionId)
              setLocalValue(this.paymentTransactionId, this.successDonationData)
            })
            .finally(() => {
              removeLocalValue("donationModel");
              removeLocalValue("wallet");
            });
        })
        .catch(({ response: { data: { errors } } }) => {
          this.paymentFailureMessage = errors[0];
          this.showDonationFailureModal = true;
        });
    },
    setDonationProgressData(data) {
      this.progessDonationData = data;
      this.donationType = this.progessDonationData.donation_info.type;
      this.showInProgressModal = true;
    },
    setDonationSuccessData(data) {
      this.successDonationData = data;
      this.donationType = this.successDonationData.type;
      this.showDonationSuccessModal = true;
    },
    handleInProgressStatus(message) {
      this.paymentProgressMessage = message;
      this.showInProgressModal = true;
    }
  },
  unmounted() {
    const getLatestPayment = getLocalValue("paymentId")
    if(getLatestPayment) {
      removeLocalValue("paymentId")
      removeLocalValue(getLatestPayment.toString())
      removeLocalValue("paymentStatus")
    }
  }
};
</script>

<style lang="scss" scoped>
.form-cont {
  width: fit-content;
}

.dir-ltr {
  direction: ltr;
}
</style>
